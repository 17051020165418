export default {
	name: "yyr",
	components: {},
	data: () => {
		return {
			loading: true,
		}
	},
	created() {
		if (!this.$route.query.code) {
			this.$router.push({ path: '/' });
			return;
		}
		this.outTradeNo = this.$route.query.code;
	},

	methods: {
		

	}
}
